import type { UseFetchOptions } from 'nuxt/app';

export function useApiFetch<T>(path: string, options: UseFetchOptions<T> = {}): Promise<T> {
    return new Promise(async (resolve, reject) => {

        const headers = {
            accept: 'application/json',
            ...useRequestHeaders(['cookie']),
        };

        path = path.indexOf('/') != 0 ? `/api/${path}` : `/api${path}`;

        const host = `${process.server ? 'http' : 'https'}://${process.server ? useRequestEvent()!.node.req.headers.host : window.location.hostname}`;
        const requestOptions: any = {
            ...options,
            credentials: 'include',
            watch: false,
            headers: {
                ...headers,
                ...options.headers,
            },
        };
        try {
            const response: any = process.server ?
                await useFetch(`${host}${path}`, requestOptions) :
                await $fetch(`${host}${path}`, requestOptions);
            resolve(process.server ? response.data.value : response);
        } catch (error: any) {
            let errorMessage = error;
            if (error.response && error.response._data) {
                errorMessage = error.response._data.error || errorMessage;
            } else if (error.data && error.data.errors) {
                errorMessage = error.data.errors;
            } else {
                errorMessage = error.message || errorMessage;
            }

            reject(errorMessage);
        }
    });
}
