import { default as _91page_934hlC860Zy9Meta } from "/vercel/path0/web/pages/[page].vue?macro=true";
import { default as dashboardBRQmUV9EusMeta } from "/vercel/path0/web/pages/dashboard.vue?macro=true";
import { default as filesi5Prtgf3ItMeta } from "/vercel/path0/web/pages/files.vue?macro=true";
import { default as finances2TV1uTtNumMeta } from "/vercel/path0/web/pages/finances.vue?macro=true";
import { default as homeGAd0R9PbclMeta } from "/vercel/path0/web/pages/home.vue?macro=true";
import { default as loginzz3zAXJZnbMeta } from "/vercel/path0/web/pages/login.vue?macro=true";
import { default as messagesRs21zDLofYMeta } from "/vercel/path0/web/pages/messages.vue?macro=true";
import { default as notificationsWhQ1lTw6sGMeta } from "/vercel/path0/web/pages/notifications.vue?macro=true";
import { default as createc4zyBxq9DFMeta } from "/vercel/path0/web/pages/pages/create.vue?macro=true";
import { default as billingD6X57DdEZoMeta } from "/vercel/path0/web/pages/portoll/settings/billing.vue?macro=true";
import { default as indexbfTXw4UsWiMeta } from "/vercel/path0/web/pages/portoll/settings/index.vue?macro=true";
import { default as notificationsFeDoZkBECYMeta } from "/vercel/path0/web/pages/portoll/settings/notifications.vue?macro=true";
import { default as plansimXan8w9EaMeta } from "/vercel/path0/web/pages/portoll/settings/plans.vue?macro=true";
import { default as subscribeTfOsUaPEGRMeta } from "/vercel/path0/web/pages/subscribe.vue?macro=true";
import { default as subscribers9Y8cI6Y2xcMeta } from "/vercel/path0/web/pages/subscribers.vue?macro=true";
import { default as component_45stubnPhnTOxcYoMeta } from "/vercel/path0/web/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubnPhnTOxcYo } from "/vercel/path0/web/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: _91page_934hlC860Zy9Meta?.name ?? "page",
    path: _91page_934hlC860Zy9Meta?.path ?? "/:page()",
    meta: _91page_934hlC860Zy9Meta || {},
    alias: _91page_934hlC860Zy9Meta?.alias || [],
    redirect: _91page_934hlC860Zy9Meta?.redirect,
    component: () => import("/vercel/path0/web/pages/[page].vue").then(m => m.default || m)
  },
  {
    name: dashboardBRQmUV9EusMeta?.name ?? "dashboard",
    path: dashboardBRQmUV9EusMeta?.path ?? "/dashboard",
    meta: dashboardBRQmUV9EusMeta || {},
    alias: dashboardBRQmUV9EusMeta?.alias || [],
    redirect: dashboardBRQmUV9EusMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: filesi5Prtgf3ItMeta?.name ?? "files",
    path: filesi5Prtgf3ItMeta?.path ?? "/files",
    meta: filesi5Prtgf3ItMeta || {},
    alias: filesi5Prtgf3ItMeta?.alias || [],
    redirect: filesi5Prtgf3ItMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/files.vue").then(m => m.default || m)
  },
  {
    name: finances2TV1uTtNumMeta?.name ?? "finances",
    path: finances2TV1uTtNumMeta?.path ?? "/finances",
    meta: finances2TV1uTtNumMeta || {},
    alias: finances2TV1uTtNumMeta?.alias || [],
    redirect: finances2TV1uTtNumMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/finances.vue").then(m => m.default || m)
  },
  {
    name: homeGAd0R9PbclMeta?.name ?? "home",
    path: homeGAd0R9PbclMeta?.path ?? "/home",
    meta: homeGAd0R9PbclMeta || {},
    alias: homeGAd0R9PbclMeta?.alias || [],
    redirect: homeGAd0R9PbclMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/home.vue").then(m => m.default || m)
  },
  {
    name: loginzz3zAXJZnbMeta?.name ?? "login",
    path: loginzz3zAXJZnbMeta?.path ?? "/login",
    meta: loginzz3zAXJZnbMeta || {},
    alias: loginzz3zAXJZnbMeta?.alias || [],
    redirect: loginzz3zAXJZnbMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: messagesRs21zDLofYMeta?.name ?? "messages",
    path: messagesRs21zDLofYMeta?.path ?? "/messages",
    meta: messagesRs21zDLofYMeta || {},
    alias: messagesRs21zDLofYMeta?.alias || [],
    redirect: messagesRs21zDLofYMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/messages.vue").then(m => m.default || m)
  },
  {
    name: notificationsWhQ1lTw6sGMeta?.name ?? "notifications",
    path: notificationsWhQ1lTw6sGMeta?.path ?? "/notifications",
    meta: notificationsWhQ1lTw6sGMeta || {},
    alias: notificationsWhQ1lTw6sGMeta?.alias || [],
    redirect: notificationsWhQ1lTw6sGMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/notifications.vue").then(m => m.default || m)
  },
  {
    name: createc4zyBxq9DFMeta?.name ?? "pages-create",
    path: createc4zyBxq9DFMeta?.path ?? "/pages/create",
    meta: createc4zyBxq9DFMeta || {},
    alias: createc4zyBxq9DFMeta?.alias || [],
    redirect: createc4zyBxq9DFMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/pages/create.vue").then(m => m.default || m)
  },
  {
    name: billingD6X57DdEZoMeta?.name ?? "portoll-settings-billing",
    path: billingD6X57DdEZoMeta?.path ?? "/portoll/settings/billing",
    meta: billingD6X57DdEZoMeta || {},
    alias: billingD6X57DdEZoMeta?.alias || [],
    redirect: billingD6X57DdEZoMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/portoll/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: indexbfTXw4UsWiMeta?.name ?? "portoll-settings",
    path: indexbfTXw4UsWiMeta?.path ?? "/portoll/settings",
    meta: indexbfTXw4UsWiMeta || {},
    alias: indexbfTXw4UsWiMeta?.alias || [],
    redirect: indexbfTXw4UsWiMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/portoll/settings/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsFeDoZkBECYMeta?.name ?? "portoll-settings-notifications",
    path: notificationsFeDoZkBECYMeta?.path ?? "/portoll/settings/notifications",
    meta: notificationsFeDoZkBECYMeta || {},
    alias: notificationsFeDoZkBECYMeta?.alias || [],
    redirect: notificationsFeDoZkBECYMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/portoll/settings/notifications.vue").then(m => m.default || m)
  },
  {
    name: plansimXan8w9EaMeta?.name ?? "portoll-settings-plans",
    path: plansimXan8w9EaMeta?.path ?? "/portoll/settings/plans",
    meta: plansimXan8w9EaMeta || {},
    alias: plansimXan8w9EaMeta?.alias || [],
    redirect: plansimXan8w9EaMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/portoll/settings/plans.vue").then(m => m.default || m)
  },
  {
    name: subscribeTfOsUaPEGRMeta?.name ?? "subscribe",
    path: subscribeTfOsUaPEGRMeta?.path ?? "/subscribe",
    meta: subscribeTfOsUaPEGRMeta || {},
    alias: subscribeTfOsUaPEGRMeta?.alias || [],
    redirect: subscribeTfOsUaPEGRMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: subscribers9Y8cI6Y2xcMeta?.name ?? "subscribers",
    path: subscribers9Y8cI6Y2xcMeta?.path ?? "/subscribers",
    meta: subscribers9Y8cI6Y2xcMeta || {},
    alias: subscribers9Y8cI6Y2xcMeta?.alias || [],
    redirect: subscribers9Y8cI6Y2xcMeta?.redirect,
    component: () => import("/vercel/path0/web/pages/subscribers.vue").then(m => m.default || m)
  },
  {
    name: component_45stubnPhnTOxcYoMeta?.name ?? undefined,
    path: component_45stubnPhnTOxcYoMeta?.path ?? "/",
    meta: component_45stubnPhnTOxcYoMeta || {},
    alias: component_45stubnPhnTOxcYoMeta?.alias || [],
    redirect: component_45stubnPhnTOxcYoMeta?.redirect,
    component: component_45stubnPhnTOxcYo
  }
]