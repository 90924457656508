import {defineStore} from 'pinia';
import type {User} from '~/types/User';
import type {Credentials} from '~/types/Credentials';

export const useAuthStore = defineStore('auth', () => {
    const loading = ref(false);
    const user = ref<User | null>(null);
    const isLoggedIn = computed(() => !!user?.value);
    const isOwner = computed(() => !!user?.value?.is_owner);

    async function login(credentials: Credentials) {
        await useApiFetch('auth/login', {
            method: 'post',
            body: credentials,
        });

        const response: any = await useApiFetch('auth/me');

        user.value = response.user;
    }

    async function logout() {
        await useApiFetch('auth/logout', {
            method: 'post',
        });

        user.value = null;

        navigateTo('/home');
    }

    async function fetchUser() {
        try {
            const response: any = await useApiFetch('auth/me');

            user.value = response.user;
        } catch (error) {
            console.error('An error occurred while fetching user:', error);
        }
    }

    const setLoading = (value: boolean) => {
        loading.value = value;
    };

    return {
        user,
        login,
        logout,
        isOwner,
        loading,
        fetchUser,
        isLoggedIn,
        setLoading,
    };
});
